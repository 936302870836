import Slider, { Settings } from "react-slick";
import React from "react";
import { Media } from "../types";
import LinearProgress from "@mui/material/LinearProgress";

export class CarouselComponent extends React.Component<{ media: Media }> {
  settings: Settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
  };

  render(): React.ReactNode {
    return (
      <Slider {...this.settings} className="slider">
        {this.props.media.children?.data
          .filter((post) => !post.media_url?.includes("video"))
          .map((post) => (
            <ImageComponent src={post.media_url} key={post.id} />
          ))}
      </Slider>
    );
  }
}

interface ImageComponentProps {
  src?: string;
}

interface ImageComponentState {
  loading: boolean;
}

export class ImageComponent extends React.Component<
  ImageComponentProps,
  ImageComponentState
> {
  constructor(props: ImageComponentProps) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  onLoad = () => {
    this.setState({
      loading: false
    });
  };

  render(): React.ReactNode {
    return (
      <div className="aspect-[4/5] relative">
        {this.state.loading ? <LinearProgress color="secondary" className="!absolute top-0 left-0 w-full" /> : <></>}
        {this.props.src ? (
          <img onLoad={this.onLoad} src={this.props.src} alt="" />
        ) : (
          <></>
        )}
      </div>
    );
  }
}
