import React from "react";
import Card from "@mui/material/Card";
import { CarouselComponent, ImageComponent } from "../carousel/carousel";
import { Media } from "../types";

interface MediaComponentProps {
  media: Media;
}

interface MediaComponentState {
  title: string;
  description: string;
}

export class MediaComponent extends React.Component<
  MediaComponentProps,
  MediaComponentState
> {
  constructor(props: MediaComponentProps) {
    super(props);

    const args = this.props.media.caption.split("\n").filter((arg) => !!arg);
    this.state = {
      title: args[0] || "",
      description: args[1] || "",
    };
  }

  content() {
    if (this.props.media.media_type === "CAROUSEL_ALBUM") {
      return <CarouselComponent media={this.props.media} />;
    } else {
      return (
        <div>
          <ImageComponent src={this.props.media.media_url} />
        </div>
      );
    }
  }

  render(): React.ReactNode {
    return (
      <Card className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 overflow-hidden">
        {this.content()}
        <div className="px-4 pt-2 font-bold">{this.state.title}</div>
        <div className="px-4 py-2">{this.state.description}</div>
      </Card>
    );
  }
}
