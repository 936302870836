import React, { Component, Ref } from "react";
import "./cakes.scss";
import { Menu } from "../components/menu/menu";
import Slider from "react-slick";
import { FirebaseUtils } from "../utils/firebase";
import { httpsCallable, HttpsCallableResult } from "firebase/functions";
import { Media, ApiRequest, ApiResponse } from "../components/cakes/types";
import { MediaComponent } from "../components/cakes/media/media";
import CircularProgress from "@mui/material/CircularProgress";
import { ThemeProvider } from "@mui/material";
import { theme } from "../utils/theme";

interface CakesPageProps {}

interface CakesPageStates {
  posts: Array<Media>;
  loading: boolean;
}

export default class CakesPage extends React.Component<
  CakesPageProps,
  CakesPageStates
> {
  slider: Slider | null = null;
  constructor(props: CakesPageProps) {
    super(props);
    this.state = {
      posts: [],
      loading: false,
    };
  }

  componentDidMount(): void {
    const functions = FirebaseUtils.i().getFunctions();

    const getPosts = httpsCallable<ApiRequest, ApiResponse>(
      functions,
      "getPosts"
    );

    this.setState({
      loading: true,
    });

    getPosts()
      .then((media: HttpsCallableResult<ApiResponse>) => {
        let after = media.data.business_discovery.media?.paging?.cursors?.after;
        this.appendPosts(media);
        return after;
      })
      .then((after) => {
        if (after) {
          return getPosts({ after });
        } else {
          return Promise.resolve(null);
        }
      })
      .then((media: HttpsCallableResult<ApiResponse> | null) => {
        this.appendPosts(media);
      });
  }

  appendPosts(media: HttpsCallableResult<ApiResponse> | null) {
    if (!media) return;
    const posts = media.data.business_discovery.media.data.filter(
      (post) =>
        post.media_type === "CAROUSEL_ALBUM" || post.media_type === "IMAGE"
    );

    this.setState({
      posts: [...this.state.posts, ...posts],
      loading: false,
    });
  }

  render(): React.ReactNode {
    return (
      <ThemeProvider theme={theme}>
        <div className="bg-gray-1 h-screen w-screen overflow-scroll">
          <div>
            <Menu inverse={true} disableAbsolute={true} />
          </div>
          {this.state.loading ? (
            <div className="flex justify-center p-8">
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <div className="flex flex-wrap p-4 gap-4 justify-center">
              {this.state.posts.map((post) => (
                <MediaComponent media={post} key={post.id} />
              ))}
            </div>
          )}
        </div>
      </ThemeProvider>
    );
  }
}
